export default {
  init() {
    $('.page-header .slider').slick({
      infinite: true,
      arrows: true,
      draggable: true,
      autoplay: true,
      speed: 750,
      fade: true,
    });

    (function() {
      var  dotsMenu;
      dotsMenu = document.querySelector('.navbar-toggler');

      dotsMenu.addEventListener('click', function() {
        return dotsMenu.classList.toggle('on');
      });

    }).call(this);
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
